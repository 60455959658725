<ng-container *transloco="let t">
  <section #contentWrapper (scroll)="onScroll()" class="content-admin">
    <main>
      @if (subscription) {
        <app-subscription-alert
          class="header-alert"
          [subscription]="subscription"
        ></app-subscription-alert>
      }

      <pxw-card class="content-body">
        <pxw-card-header>
          {{ t('home.mainTitle') }}
        </pxw-card-header>

        @if (allowedFeatures.catalog || allowedFeatures.orders || allowedFeatures.statistics) {
          <section class="main-options">
            @if (allowedFeatures.catalog) {
              <pxw-option-menu
                id="catalog-menu"
                size="lg"
                [title]="t('section.catalog')"
                [routerLink]="['../catalogo']"
                (click)="clickOption('catalogue')"
              >
                <pxw-icon icon="catalog" />
              </pxw-option-menu>
            }
            @if (allowedFeatures.orders) {
              <pxw-option-menu
                id="orders-menu"
                size="lg"
                [title]="t('section.orders')"
                [routerLink]="['../pedidos']"
                (click)="clickOption('orderList')"
              >
                <pxw-icon icon="orders" />
              </pxw-option-menu>
            }
            @if (allowedFeatures.statistics) {
              <pxw-option-menu
                id="statistics-menu"
                size="lg"
                [title]="t('section.statistics')"
                [routerLink]="['../estadisticas']"
                (click)="clickOption('statistics')"
              >
                <pxw-icon icon="statistics" />
              </pxw-option-menu>
            }
          </section>

          <hr class="ui-separator" />
        }

        <div class="secondary-options-wrapper">
          @if (
            allowedFeatures.generalInfo ||
            allowedFeatures.workingHours ||
            allowedFeatures.theme ||
            allowedFeatures.announcements
          ) {
            <section>
              <h2 class="ui-label ui-label--md">{{ t('home.storeTitle') }}</h2>
              <div class="secondary-options">
                @if (allowedFeatures.generalInfo) {
                  <pxw-section-menu
                    [title]="t('section.establishment')"
                    [description]="t('section.establishmentDescription')"
                    [routerLink]="['../general']"
                    (click)="clickOption('general')"
                  >
                    <pxw-icon icon="store" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.workingHours) {
                  <pxw-section-menu
                    [title]="t('section.workingHours')"
                    [description]="t('section.workingHoursDescription')"
                    [routerLink]="['../horarios']"
                    (click)="clickOption('workingHours')"
                  >
                    <pxw-icon icon="clock" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.theme) {
                  <pxw-section-menu
                    [title]="t('section.theme')"
                    [description]="t('section.themeDescription')"
                    [routerLink]="['../tema']"
                    (click)="clickOption('theme')"
                  >
                    <pxw-icon icon="design" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.announcements) {
                  <pxw-section-menu
                    [title]="t('section.announcements')"
                    [description]="t('section.announcementsDescription')"
                    [routerLink]="['../anuncios']"
                    (click)="clickOption('announcements')"
                  >
                    <pxw-icon icon="advertisement" />
                  </pxw-section-menu>
                }
              </div>
            </section>
          }

          @if (
            allowedFeatures.paymentMethods ||
            allowedFeatures.shippingOptions ||
            allowedFeatures.customFields
          ) {
            <section>
              <h2 class="ui-label ui-label--md">{{ t('home.checkoutTitle') }}</h2>
              <div class="secondary-options">
                @if (allowedFeatures.paymentMethods) {
                  <pxw-section-menu
                    [title]="t('section.paymentMethods')"
                    [description]="t('section.paymentMethodsDescription')"
                    [routerLink]="['../metodos-pago']"
                    (click)="clickOption('paymentMethods')"
                  >
                    <pxw-icon icon="payment" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.shippingOptions) {
                  <pxw-section-menu
                    [title]="t('section.shippingOptions')"
                    [description]="t('section.shippingOptionsDescription')"
                    [routerLink]="['../formas-de-envio']"
                    (click)="clickOption('shippingOptions')"
                  >
                    <pxw-icon icon="shipping" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.customFields) {
                  <pxw-section-menu
                    [title]="t('section.checkout')"
                    [description]="t('section.checkoutDescription')"
                    [routerLink]="['../checkout']"
                    (click)="clickOption('checkout')"
                  >
                    <pxw-icon icon="form" />
                  </pxw-section-menu>
                }
              </div>
            </section>
          }

          @if (
            allowedFeatures.seoSocialMedia ||
            allowedFeatures.analytics ||
            allowedFeatures.integrations
          ) {
            <section>
              <h2 class="ui-label ui-label--md">{{ t('home.socialMediaTitle') }}</h2>
              <div class="secondary-options">
                @if (allowedFeatures.seoSocialMedia) {
                  <pxw-section-menu
                    [title]="t('section.seoSocialMedia')"
                    [description]="t('section.seoSocialMediaDescription')"
                    [routerLink]="['../seo-social-media']"
                    (click)="clickOption('seoSocialMedia')"
                  >
                    <pxw-icon icon="search-engine" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.analytics) {
                  <pxw-section-menu
                    [title]="
                      allowedFeatures.advancedAnalytics
                        ? t('section.analyticsPro')
                        : t('section.analytics')
                    "
                    [description]="t('section.analyticsDescription')"
                    [routerLink]="['../analytics']"
                    (click)="clickOption('analytics')"
                  >
                    <pxw-icon icon="analytics" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.integrations) {
                  <pxw-section-menu
                    [title]="t('section.integrations')"
                    [description]="t('section.integrationsDescription')"
                    [routerLink]="['../integraciones']"
                    (click)="clickOption('integrations')"
                  >
                    <pxw-icon icon="integrations" />
                  </pxw-section-menu>
                }
              </div>
            </section>
          }

          @if (allowedFeatures.ads || allowedFeatures.qrCode) {
            <section>
              <h2 class="ui-label ui-label--md">{{ t('home.advertisingTitle') }}</h2>
              <div class="secondary-options">
                @if (allowedFeatures.ads) {
                  <pxw-section-menu
                    [title]="t('section.ads')"
                    [description]="t('section.adsDescription')"
                    [routerLink]="['../publicidad']"
                    (click)="clickOption('ads')"
                  >
                    <pxw-icon icon="growth" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.qrCode) {
                  <pxw-section-menu
                    [title]="t('section.qrCode')"
                    [description]="t('section.qrCodeDescription')"
                    [routerLink]="['../codigo-qr']"
                    (click)="clickOption('qrCode')"
                  >
                    <pxw-icon icon="qr-code" />
                  </pxw-section-menu>
                }
              </div>
            </section>
          }

          @if (allowedFeatures.coupons) {
            <section>
              <h2 class="ui-label ui-label--md">{{ t('home.upsaleTitle') }}</h2>
              <div class="secondary-options">
                @if (allowedFeatures.coupons) {
                  <pxw-section-menu
                    [title]="t('section.coupons')"
                    [description]="t('section.couponsDescription')"
                    [routerLink]="['../cupones']"
                    (click)="clickOption('coupons')"
                  >
                    <pxw-icon icon="coupons" />
                  </pxw-section-menu>
                }
              </div>
            </section>
          }

          @if (hasSubscription || allowedFeatures.userManagement) {
            <section>
              <h2 class="ui-label ui-label--md">{{ t('home.accountTitle') }}</h2>
              <div class="secondary-options">
                @if (hasSubscription) {
                  <pxw-section-menu
                    [title]="t('section.subscription')"
                    [description]="t('section.subscriptionDescription')"
                    [routerLink]="['../suscripcion']"
                    (click)="clickOption('subscription')"
                  >
                    <pxw-icon icon="subscription" />
                  </pxw-section-menu>
                }

                @if (allowedFeatures.userManagement) {
                  <pxw-section-menu
                    [title]="t('section.adminUsers')"
                    [description]="t('section.adminUsersDescription')"
                    [routerLink]="['../usuarios']"
                    (click)="clickOption('adminUsers')"
                  >
                    <pxw-icon icon="users" />
                  </pxw-section-menu>
                }
              </div>
            </section>
          }
        </div>
      </pxw-card>

      <pxw-button
        class="help-btn"
        color="yellow"
        size="lg"
        [rounded]="true"
        (click)="goToHelpCenter()"
      >
        <pxw-icon icon="help" />
        {{ t('home.help') }}
      </pxw-button>
    </main>
    <aside>
      @if (hasSubscription) {
        <pxw-card size="sm">
          <pxw-card-header>
            {{ t('subscriptionCard.title') }}
            <div class="subscription-status">
              @if (displaySubscriptionPaymentAlert) {
                <pxw-tag type="warning">{{ t('subscriptionCard.paymentIssue') }}</pxw-tag>
              } @else {
                <pxw-tag [type]="subscription.status === 'active' ? 'success' : 'danger'">
                  {{
                    subscription.status === 'active'
                      ? t('subscriptionCard.statusActive')
                      : t('subscriptionCard.statusInactive')
                  }}
                </pxw-tag>
              }
            </div>
          </pxw-card-header>
          <app-subscription-alert [subscription]="subscription"></app-subscription-alert>
          <pxw-list theme="light">
            <pxw-list-item
              [routerLink]="['../suscripcion']"
              (click)="clickLinkNavigation('subscriptionInfo')"
            >
              <pxw-icon icon="info" />
              {{ t('subscriptionCard.infoLink') }}
            </pxw-list-item>

            @if (allowedFeatures.subscriptionManagement) {
              <pxw-list-item
                color="pink"
                [routerLink]="['../suscripcion/plans']"
                (click)="clickLinkNavigation('subscriptionUpgrade')"
              >
                <pxw-icon icon="rocket" />
                {{ t('subscriptionCard.upgradeLink') }}
              </pxw-list-item>
            }
          </pxw-list>
        </pxw-card>
      }

      <pxw-card theme="dark" size="sm">
        <pxw-card-header>{{ t('helpCard.title') }}</pxw-card-header>
        <pxw-list theme="dark">
          <pxw-list-item [href]="helpCenterUrl" (click)="clickLinkNavigation('helpCenter')">
            <pxw-icon icon="help" />
            {{ t('helpCard.helpCenterLink') }}
          </pxw-list-item>
          <pxw-list-item [href]="youtubeUrl" (click)="clickLinkNavigation('youtube')">
            <pxw-icon icon="youtube" />
            {{ t('helpCard.youtubeLink') }}
          </pxw-list-item>
        </pxw-list>
      </pxw-card>

      <pxw-card theme="dark" size="sm">
        <pxw-card-header>{{ t('aboutPedixCard.title') }}</pxw-card-header>
        <pxw-list theme="dark">
          <pxw-list-item [href]="websiteUrl" (click)="clickLinkNavigation('web')">
            <pxw-icon icon="web" />
            {{ t('aboutPedixCard.websiteLink') }}
          </pxw-list-item>
          <pxw-list-item [href]="instagramUrl" (click)="clickLinkNavigation('instagram')">
            <pxw-icon icon="instagram" />
            {{ t('aboutPedixCard.instagramLink') }}
          </pxw-list-item>
        </pxw-list>
      </pxw-card>
    </aside>
  </section>
</ng-container>
