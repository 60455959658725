import { Injectable, inject } from '@angular/core';
import {
  Auth,
  authState,
  signInWithCustomToken,
  User as FirebaseUser,
  updateCurrentUser,
} from '@angular/fire/auth';
import { skip } from 'rxjs/operators';
import { User } from '@pedix-workspace/utils';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './features/auth/login/login.service';
import { environment } from '../environments/environment';
import { AppAdminSessionService } from './features/app-admin-session.service';

@Injectable({
  providedIn: 'root',
})
export class AppUserService {
  private _user: User | null;

  get user(): User | null {
    return this._user;
  }

  get isSuperAdmin() {
    return Boolean(this.user?.claims?.appSuperAdmin);
  }

  get isEstablishmentUser() {
    return this.user?.email.endsWith(`@${environment.emailDomain}`);
  }

  get isAdminUser() {
    return !this.isEstablishmentUser;
  }

  private auth = inject(Auth);
  private httpClient = inject(HttpClient);
  private loginService = inject(LoginService);
  private adminSessionService = inject(AppAdminSessionService);

  private redirectUrl: string | null = null;

  constructor() {
    // Listen for authentication updates
    authState(this.auth)
      .pipe(skip(1))
      .subscribe((user: FirebaseUser | null) => {
        this.setUserStateFromFirebase(user);
      });
  }

  async checkAndRestoreAuthState(): Promise<boolean> {
    try {
      const { customToken } = await this.httpClient
        .post<{ customToken?: string }>(
          '/auth/state',
          {},
          {
            withCredentials: true,
          },
        )
        .toPromise();

      if (!customToken) {
        return false;
      }

      const userCredential = await signInWithCustomToken(this.auth, customToken);

      const idTokenResult = await this.setUserStateFromFirebase(userCredential.user);

      if (idTokenResult) {
        // NOTE: we call this to refresh session (which has fixed expiration and cannot be automatically renewed)
        // It is not being awaited on purpuse
        // This means that as long as the user uses the system while the token is still valid, it's expiration time
        // will be automatically extended (for the given time configured in the backend service)
        this.loginService.setOrRefreshUserSession(idTokenResult.token);
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  async getLoggedUserPromise(): Promise<User | null> {
    return Promise.resolve(this.user);
  }

  async createPasswordRecoveryRequest(establishmentSlug: string, registrationEmail: string) {
    await this.httpClient
      .post(`${environment.apiUrl}/pedix/password-recovery-request`, {
        establishmentSlug,
        registrationEmail,
      })
      .toPromise();
  }

  async updatePassword(
    passwordRecoveryRequestId: string,
    establishmentSlug: string,
    newPassword: string,
  ) {
    await this.httpClient
      .post(`${environment.apiUrl}/pedix/update-password`, {
        passwordRecoveryRequestId,
        establishmentSlug,
        newPassword,
      })
      .toPromise();
  }

  async getObfuscatedEstablishmentEmail(establishmentSlug: string): Promise<string> {
    const response = await this.httpClient
      .get<{
        obfuscatedEmail: string;
      }>(
        `${environment.apiUrl}/pedix/obfuscated-establishment-email?establishmentSlug=${establishmentSlug}`,
      )
      .toPromise();

    return response.obfuscatedEmail;
  }

  async updateCurrentUser(user: FirebaseUser) {
    await updateCurrentUser(this.auth, user);

    const idTokenResult = await user.getIdTokenResult();

    await this.loginService.setOrRefreshUserSession(idTokenResult.token);
  }

  private async setUserStateFromFirebase(user: FirebaseUser | null) {
    if (user) {
      if (this._user && this._user.uid !== user.uid) {
        this.adminSessionService.clear();
      }
      const idTokenResult = await user.getIdTokenResult();

      this._user = {
        uid: user.uid,
        email: user.email,
        claims: {
          appSuperAdmin: Boolean(idTokenResult.claims['appSuperAdmin']),
        },
      };
      return idTokenResult;
    } else {
      this.adminSessionService.clear();

      this._user = null;

      return null;
    }
  }
  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  clearRedirectUrl(): void {
    this.redirectUrl = null;
  }
}
