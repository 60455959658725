import {
  Component,
  OnInit,
  Renderer2,
  AfterContentInit,
  ViewChild,
  OnDestroy,
  inject,
  signal,
} from '@angular/core';
import {
  AnalyticsService,
  ModulePreloadManualStrategyService,
} from '@pedix-workspace/pedixapp-core-services';
import { ThemeService } from '@pedix-workspace/angular-utils';
import { Establishment, getDefaultLocaleId } from '@pedix-workspace/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';
import {
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ScriptService } from 'ngx-script-loader';
import { DrawerComponent } from '@pedix-workspace/angular-ui-nav';
import { Subscription } from 'rxjs';
import { locale } from 'devextreme/localization';
import { EstablishmentService } from '../../catalog/establishment.service';
import { IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { HeaderComponent } from '../header/header.component';
import { TranslocoDirective } from '@ngneat/transloco';
import { ProductService } from '../../catalog/product/product.service';
import { CategoriesService } from '../../catalog/categories/categories.service';
import { AppUserService } from '../../../app-user.service';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    HeaderComponent,
    DrawerComponent,
    SideMenuComponent,
    IconLoadingComponent,
    RouterOutlet,
  ],
})
export class AppLayoutComponent implements OnInit, AfterContentInit, OnDestroy {
  static firstRender = true;

  @ViewChild(DrawerComponent) drawer: DrawerComponent;

  establishment: Establishment;
  sideMenuOpen: boolean | null = null;
  isNuboReady = false;
  loadingSection = false;
  activeSection: 'catalog' | 'clonning' | null; // e.g. "| 'orders' | 'general', etc"

  #adminUserEstablishments = signal<Establishment[]>([]);

  private routerSubscription: Subscription;

  private document = inject(DOCUMENT);

  private renderer = inject(Renderer2);
  private establishmentService = inject(EstablishmentService);
  private themeService = inject(ThemeService);
  private analytics = inject(AnalyticsService);
  private router = inject(Router);
  private scriptService = inject(ScriptService);
  private modulePreloaderService = inject(ModulePreloadManualStrategyService);
  private productsService = inject(ProductService);
  private categoriesService = inject(CategoriesService);
  private userService = inject(AppUserService);

  get hasSubsidiaries() {
    return this.establishment?.subsidiaries?.length > 0;
  }

  get displayCatalogMenu() {
    return this.activeSection !== null;
  }

  get shouldAutoOpenSideMenu() {
    return this.displayCatalogMenu;
  }

  get adminUserEstablishments() {
    return this.#adminUserEstablishments();
  }

  ngOnInit() {
    this.updateActiveSection(this.router.url);
    this.routerSubscription = this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof RouteConfigLoadStart && !event.route.data?.['isPreload']) {
        this.loadingSection = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingSection = false;
      }
      if (event instanceof NavigationEnd) {
        this.updateActiveSection(event.urlAfterRedirects);
      }
    });

    if (this.userService.isAdminUser) {
      this.establishmentService
        .getEstablishmentsForAdminUser(this.userService.user.uid)
        .then(establishments => {
          this.#adminUserEstablishments.set(establishments);
        });
    }

    this.establishmentService.currentEstablishment$
      .pipe(untilDestroyed(this))
      .subscribe(establishment => {
        if (!establishment) {
          this.establishment = null;
          return;
        }
        this.establishment = establishment;

        this.analytics.establishmentSlug = this.establishment.slug;
        this.analytics.establishmentId = this.establishment.id;
        this.analytics.establishmentCurrency = this.establishment.currencyCode;

        if (AppLayoutComponent.firstRender) {
          AppLayoutComponent.firstRender = false;

          this.analytics.userLanded();
        }

        if (this.establishment.theme) {
          this.themeService.configureTheme(this.establishment.theme);
        } else {
          this.themeService.resetTheme();
        }

        this.renderer.addClass(this.document.documentElement, 'ready');

        locale(getDefaultLocaleId(this.establishment.countryCode));

        // Watches for product and categories changes (useful for background updates, such as stock changes or linked document ref changes)
        this.productsService.watchForProductUpdates(this.establishment.id);
        this.categoriesService.watchForCategoryUpdates(this.establishment.id);
      });
  }

  ngAfterContentInit() {
    this.scriptService
      .loadScript('/assets/scripts/nubo.js')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const nuboOptions = {
          projectId: 'XLayiyBU7ece14iGXCpBMtji',
          showDefaultbutton: false,
          announcesTitle: 'Novedades',
          user: {
            id: this.establishment.slug,
            first_name: this.establishment.slug,
            email: this.establishment.contactInfo?.email,
            role: 'customer',
            label: this.establishment.name,
          },
        };
        window['_nubo']('init', nuboOptions);

        this.isNuboReady = true;
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  onClickLateralMenu($event) {
    // Preloads auth module for better UX
    this.modulePreloaderService.preloadModuleByName('auth');

    this.sideMenuOpen = !this.sideMenuOpen;

    const path = $event.path || $event?.composedPath();

    if (this.sideMenuOpen && (!path || !path.includes(document.querySelector('.home-button')))) {
      this.sideMenuOpen = false;
    }
  }

  onNavItemClick() {
    if (this.drawer.display === 'desktop' && this.drawer.shouldAutoOpen === true) {
      return;
    }
    this.sideMenuOpen = false;
  }

  private updateActiveSection(url: string) {
    if (url.startsWith('/catalogo')) {
      this.activeSection = 'catalog';
    } else if (url.startsWith('/clonacion')) {
      this.activeSection = 'clonning';
    } else {
      this.activeSection = null;
    }
  }
}
