import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeaturesAndRolesService, AllowedFeatures } from './app-features-and-roles.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

export type FeaturesAndRolesGuardFn = (allowedFeatures: AllowedFeatures) => boolean;
export type FeaturesAndRolesGuardOptions = {
  defaultRedirect?: string[];
};

export const featuresAndRolesGuard =
  (testFn: FeaturesAndRolesGuardFn, options: FeaturesAndRolesGuardOptions = {}): CanActivateFn =>
  () => {
    const featuresAndRolesService = inject(FeaturesAndRolesService);
    const toastrService = inject(ToastrService);
    const t = inject(TranslocoService);
    const router = inject(Router);

    const canActivate = testFn(featuresAndRolesService.getAllowedFeatures());

    if (!canActivate) {
      toastrService.error(
        t.translate('error.notEnoughPermissionsDescription'),
        t.translate('error.notEnoughPermissions'),
      );

      router.navigate(options.defaultRedirect || ['/inicio']);

      return false;
    }
    return true;
  };
