<ng-container *transloco="let t">
  <pxw-modal-dialog
    [title]="t('establishmentSelector.title')"
    type="modal"
    [showCloseButton]="showCloseButton && !loadingRedirect"
  >
    <pxw-icon icon="building" />
    @if (establishments) {
      <ng-template #modalContent>
        <pxw-alert type="info" size="sm">
          {{ t('establishmentSelector.intro') }}
        </pxw-alert>
        <pxw-select-box
          name="selectedEstablishment"
          [placeholder]="t('establishmentSelector.title')"
          [options]="establishments"
          labelKey="name"
          [searchable]="true"
          [allowNewValues]="false"
          [ngModel]="null"
          [disabled]="loadingRedirect"
          (ngModelChange)="onSelectedEstablishment($event)"
        >
          @if (loadingRedirect) {
            <span class="addon-prepend">
              <pxw-icon icon="loading" [spin]="true" size="sm" />
            </span>
          }
          <ng-template #optionItemTemplate let-establishment>
            <div class="establishment-name">{{ establishment.name }}</div>
            @if (selectedEstablishment?.slug === establishment.slug) {
              <pxw-button size="sm" color="silver" [rounded]="true" appareance="outline"
                >Actual</pxw-button
              >
            } @else {
              <pxw-button size="sm" color="yellow" [rounded]="true">Seleccionar</pxw-button>
            }
          </ng-template>
        </pxw-select-box>
      </ng-template>
    }
  </pxw-modal-dialog>
</ng-container>
