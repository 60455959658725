<ng-container *transloco="let t">
  @if (loadingEstablishments) {
    <div class="ui-loader ui-loader--with-overlay">
      <pxw-icon icon="loading" [spin]="true"></pxw-icon>
    </div>
  }
  <app-establishment-selector-dialog
    [establishments]="establishments"
    [loadingRedirect]="loadingRedirect"
    (selectedEstablishmentChange)="onSelectedEstablishment($event)"
  />
</ng-container>
