import { Component, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { TranslocoDirective } from '@ngneat/transloco';
import { Establishment } from '@pedix-workspace/utils';
import { IconBuildingComponent, IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { AlertComponent } from '@pedix-workspace/angular-ui-alert';
import { SelectBoxComponent } from '@pedix-workspace/angular-ui-form-reactive';
import { FormsModule } from '@angular/forms';
import { noop } from 'lodash-es';

@Component({
  selector: 'app-establishment-selector-dialog',
  templateUrl: './establishment-selector-dialog.component.html',
  styleUrls: ['./establishment-selector-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    ModalDialogComponent,
    IconLoadingComponent,
    IconBuildingComponent,
    ButtonComponent,
    AlertComponent,
    SelectBoxComponent,
    FormsModule,
  ],
})
export class EstablishmentSelectorDialogComponent implements OnDestroy {
  @ViewChild(ModalDialogComponent)
  establishmentSelectorDialog: ModalDialogComponent;

  @Input({ required: true }) establishments: Establishment[];
  @Input() showCloseButton = false;
  @Input() selectedEstablishment: Establishment;
  @Input() loadingRedirect = false;

  @Output() selectedEstablishmentChange = new EventEmitter<Establishment>();

  ngOnDestroy(): void {
    this.establishmentSelectorDialog?.close();
  }

  open() {
    this.establishmentSelectorDialog.open().catch(noop);
  }

  close() {
    this.establishmentSelectorDialog.close();
  }

  onSelectedEstablishment(establishment: Establishment) {
    this.selectedEstablishmentChange.emit(establishment);
  }
}
